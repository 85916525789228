import React, { useState, useContext, useEffect } from 'react'
import "../../../assets/css/TCGL_V_A1/id-upload.scss";
import "../../../assets/css/TCGL_V_A1/car-registration.scss";
import Footer from '../../Includes/Layouts/TCGL_V_A1/Footer';
import HeaderUpload from '../../Includes/Layouts/TCGL_V_A1/HeaderUpload';
import uploadicon from "../../../assets/img/TCGL_V_A1/idupload-icon.png"
import iddriving from "../../../assets/img/TCGL_V_A1/id-driving.png"
import IdUploadModal from '../../Includes/Layouts/TCGL_V_A1/IdUploadModal';
import GetVisitorsParams from "../../../Utility/GetVisitorsParams";
import { useHistory } from "react-router-dom";
import { CheckUUID } from "../../../Utility/CheckUUID";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import { useIdUpload } from "../../../Hooks/useIdUpload";
import { getQueryString } from "../../../Utility/QueryString";
import { useSkipIdUpload } from "../../../Hooks/useSkipIdUpload";
import Button from "../../UI/Button";
import GTMDataLayer from '../../../Utility/GTMDataLayer';

const TCGL_V_A4_Id_Upload = () => {

  const { isCheck } = CheckUUID();
  isCheck();
  const [isModalVisible, setIsModalVisible] = useState({
    show: false,
    type: "",
  });
  const [progressbar, setProgressBar] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [idType, setIdType] = useState("");
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [userfile, setUserfile] = useState({
    preview: "",
    title: "",
    type: "",
  });
  const [selectedFileType, setSelectedFileType] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [errorMessage, setErrorMessage] = useState();
  const [errorMessageUpload, setErrorMessageUpload] = useState();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const formParameters = JSON.parse(localStorage.getItem("formData"));
  const [isInValidFile, setIsInValidFile] = useState(false);
  const [base64Image, setBase64Image] = useState("");
  const { saveIdUpload, isLoading } = useIdUpload();
  const history = useHistory();
  const queryString = getQueryString();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const { skipidupload } = useSkipIdUpload();
  const splitName = new URLSearchParams(queryString).get("splitName");
  const uuid = new URLSearchParams(queryString).get("uuid");
  visitorParameters.visitor_parameters.uuid = uuid;
  let nextPage = "TCGL_V_A1/thankyou";

  const [isSkipClicked, setIsSkipClicked] = useState(true);
  const handleUploadClick = (fileType) => {
    setErrorMessage("");
    setIsModalVisible({ show: true, type: fileType });
  };
  const handleCloseModal = () => {
    setIsModalVisible({ show: false, type: "" });
    setSelectedFileType("");
    setIsFileSelected(false);
    setUserfile({ preview: "", title: "", type: "" });
    setBase64Image("");
    setErrorMessage("");
    setErrorMessageUpload("");
    setProgressBar(false);
    setBtnLoader(false);
  };
  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    setBase64Image(binaryString);
  };
  const skipIdPage = async () => {
    GTMDataLayer({
      clickEvent: "slideclick",
      question: "verify identity",
      answer: "",
    });
    const response = await skipidupload(
      visitorParameters.visitor_parameters.uuid,
      splitName,
      "id_upload",
      ""
    );

    history.push(
      "/" +
      nextPage +
      "?uuid=" +
      visitorParameters.visitor_parameters.uuid +
      "&splitName=" +
      splitName
    );
  };
  const handleFileChange = (event) => {
    setProgressBar(true);
    const timer = setTimeout(() => {
      setProgressBar(false);
      const imageFile = event.target.files[0];
      if (imageFile) {
        setErrorMessageUpload();
        setErrorMessage("");
        const extension = imageFile.type;
        const imgsize = imageFile.size;
        const allowedExtensions = [
          "image/jpeg",
          "image/png",
          "application/pdf",
          "message/rfc822",
          "application/zip"
        ];
        if (allowedExtensions.indexOf(extension) === -1) {
          setIsInValidFile(true);
          setErrorMessage(
            "Invalid File Format.The uploaded ID type should be an image in jpeg, png, or jpg"
          );
          return;
        } else if (imgsize > 1024 * 1024 * 10) {
          setIsInValidFile(true);
          setErrorMessage("Please choose a file less than 10MB");
          return;
        } else {
          setIsInValidFile(false);
          setIsFileSelected(true);
          setUserfile({
            preview: URL.createObjectURL(imageFile),
            title: imageFile.name,
            type: imageFile.type,
          });

          const reader = new FileReader();
          reader.onload = _handleReaderLoaded;
          reader.readAsDataURL(imageFile);
        }
      } else {
        setIsInValidFile(true);
        setUserfile({ preview: "", title: "", type: "" });
      }
      setProgressBar(false);
    }, 2000);
  };

  const handleSubmit = async (event) => {
    setBtnLoader(true);
    event.preventDefault();

    if (isFileSelected) {
      GTMDataLayer({
        clickEvent: "slideclick",
        question: "verify identity",
        answer: "",
      });

      setErrorMessageUpload();
      const uploadData = {
        imageFile: base64Image,
        captureType: isModalVisible.type.split("_")[0],
        documentType: isModalVisible.type.split("_")[0],
      };
      const response = await saveIdUpload(
        uploadData,
        visitorParameters.visitor_parameters,
        formParameters,
        visitorParameters.data,
        queryString,
        "user_docs_store"
      );
      setSelectedFile("");
      setBase64Image("");
      setIsModalVisible({ show: false, type: "" });
      setSelectedFileType("");
      setErrorMessage("");
      setSelectedFileType(isModalVisible.type);
      setIsFormSubmitted(true);
      setIsSkipClicked(false);

      history.push("/" + nextPage + "?uuid=" + visitorParameters.visitor_parameters.uuid + "&splitName=" + splitName);
    } else {
      setErrorMessageUpload("Please upload a file");
      setIsFormSubmitted(false);
    }
    setBtnLoader(false);
  };

  const handleNextClickModal = () => {
    if (!isFileSelected) {
      setErrorMessage("Please upload a file");
      return false;
    } else if (!isFormSubmitted) {
      setErrorMessage("Please press submit button");
      return false;
    }
  };

  const handleNextClick = () => {
    if (!isFileSelected) {
      setErrorMessage("Please upload a file");
      return false;
    }
  };

  const retakeClick = (e) => {
    setUserfile({ preview: "", title: "", type: "" });
    setIsFileSelected(false);
    setSelectedFile("");
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  return (

    <div>
      <GetVisitorsParams />
      <div className="TCGL_V_A1_Id_Upload car-reg-page TCGL_V_A1 ">

      <div className="supagebg1">
        <HeaderUpload />
        <section className="banner justify-content-start banner-subpage nobg">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12  align-self-center order-lg-1 order-2 offset-lg-3 bg-lightblue ">
                <form>
                  <div className="slide1carreg" style={{}}>
                    <div className="form-header-sub2 row formsub-idbg text-center px-2">
                      <h4>Thanks</h4>
                      <p>We will review the information provided and will be back in touch about any potential claims identified.</p>
                    </div>
                    <div className="form-header d-flex align-items-center">
                      <div className="col-lg-2 col-3 text-center">
                        <img src={uploadicon} alt="" />
                      </div>
                      <div className="col-lg-10 col-9 bor-l  align-self-center">
                        <h4 style={{ textAlign: "left" }}>
                          <span
                            style={{
                              color: "rgb(160, 160, 160)",
                              textAlign: "left",
                              fontSize: 20
                            }}
                          >
                            Upload{" "}
                          </span>
                          <span>Your ID</span>
                        </h4>
                        <p
                          style={{
                            fontSize: 14,
                            color: "rgb(0, 0, 0)",
                            margin: 0,
                            textAlign: "left"
                          }}
                        >
                          Your lenders may require your ID to process your claims
                        </p>
                      </div>
                    </div>
                    <div className="drop-in col-12">
                      {/* car1 */}
                      <div className="col-lg-12 col-12 iduploadcol">

                        <div className="idcol">
                          <div className="col-2 idicon">
                            <img src={iddriving} alt="" />
                          </div>
                          <div className="col-10 buttoncol ">
                            <h3>Driver's Licence</h3>
                            {selectedFileType == "DrivingLicense_doc" ? (
                              <div className="click-upload p-1 px-2 pe-3 fileupload ">
                                <i className="bi bi-check-circle-fill me-1"></i>
                                File Uploaded
                              </div>
                            ) : (
                              <Button
                                type="button"
                                className="clickupload"
                                id="DrivingLicense"
                                name="idType"
                                onClick={() =>
                                  handleUploadClick("DrivingLicense_doc")
                                }
                                buttonText="Click to upload"
                                disabled={
                                  selectedFileType &&
                                  selectedFileType !== "DrivingLicense_doc"
                                }
                              />
                            )}
                          </div>


                          <Button
                            className="fileupload d-none"
                            id=""
                            type="button"
                            onClick={skipIdPage}
                            buttonText=" File Uploaded"
                          />
                        </div>
                        {errorMessage && isModalVisible.show == false && (
                          <div
                            className="validation-error mt-3 text-center"
                            style={{
                              background: "red",
                              borderRadius: "4px",
                              width: "100%",
                              marginLeft: "0px",
                              marginTop: "5px",
                              padding: "5px",
                              color: "white",
                            }}
                          >
                            {errorMessage}
                          </div>
                        )}
                      </div>
                      <div className="row">
                        <div className="col-4">
                          {" "}

                          {isSkipClicked && (
                              <Button
                                className="btn-back  mt-4"
                                id=""
                                type="button"
                                onClick={skipIdPage}
                                buttonText="Skip"
                              />
                          )}
                        </div>
                        <div className="col-8 pr-0">
                          {" "}

                          <Button
                            className="btn-banner btn-fullwidth mt-4 w-100 nextthankyoupage next-css"
                            id=""
                            type="button"
                            onClick={handleNextClick}
                            buttonText="Next"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* slide 1 end */}
                </form>
              </div>
            </div>
          </div>
        </section>
        {/* End Banner */}
        <div className="btm_sec">
          <Footer />
        </div>
      </div>
      {/* id upload popup */}

      <IdUploadModal
        isModalVisible={isModalVisible}
        handleCloseModal={handleCloseModal}
        isFileSelected={isFileSelected}
        userfile={userfile}
        progressbar={progressbar}
        handleFileChange={handleFileChange}
        retakeClick={retakeClick}
        handleSubmit={handleSubmit}
        btnLoader={btnLoader}
        errorMessageUpload={errorMessageUpload}
        errorMessage={errorMessage}
        handleNextClickModal={handleNextClickModal}
        idType={"Driving License"}
        isFormSubmitted={isFormSubmitted}
      />
</div>
    </div>
  )
}

export default TCGL_V_A4_Id_Upload