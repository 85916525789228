import Button from "../../UI/Button";
import "../../../assets/css/TCGL_V_A1/lenders.scss"



const ShowLenders = ({ slideChange, setAutoSelectAllLenders }) => {
  const showLenderSlide = (btn) => {
    if (btn === "max") {
      setAutoSelectAllLenders(true);
    } else if (btn === "manual") {
      setAutoSelectAllLenders(false);
    }
  }

  return (
    <div className="TCGL_V_A1_Lenders slide0">
      {/* <div id='more-lenders' className="additional-lenders pb-4"> */}
      <div className="lenders">
      {/* <fieldset className=" mb-3">
          <legend className="px-2 mb-2 lg-mb-3 text-start" style={{ width: "auto" }}>
          Choose one option
          </legend> */}
        <Button
          type="button"
          buttonText="Max Refund Search (Recommended)"
          name="slide_1"
          className="btn vbtn btn-primary w-100 lenderslide-button mt-1 max zoom-in-zoom-out"
          onClick={(e) => { slideChange(e); showLenderSlide("max"); }}
        />
        <Button
          type="button"
          buttonText="Manually Select Lenders"
          name="slide_1"
          className="btn vbtn btn-primary w-100 lenderslide-button mt-3 mb-2 manual"
          onClick={(e) => { slideChange(e); showLenderSlide("manual"); }}
        />
      {/* </fieldset> */}
      </div>
      {/* </div> */}
    </div>

  )
};



export default ShowLenders;