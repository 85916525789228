
const SubHeading = (props) => {
    return (
      props.heading.slide === "slide3" ? (
        <div className="form-header d-flex align-items-center">
          <div className="col-lg-2 col-3">
            <img src={props.contentIcon} alt="" />
          </div>
          <div className="col-lg-10 col-9 bor-l align-self-center">
            <h5>
            Help us keep you updated about your potential refund amount
            </h5>
          </div>
        </div>
      ) : (
        <div className="form-header d-flex align-items-center">
          <div className="col-lg-2 col-3">
            <img src={props.contentIcon} alt="" />
          </div>
          <div className="col-lg-10 col-9 bor-l align-self-center">
            <h4>
              {/* {props.heading.slide == "" && "Select your"}{" "} */}
              {(props.heading.slide == "slide1" || props.heading.slide == "back2") && "Select"}{" "}
              {(props.heading.slide == "slide2" || props.heading.slide == "back3") && "Enter your"}{" "}
              {(props.heading.slide == "slide0" || props.heading.slide == "back1") && "Enter your"}{" "}
              <br className="d-block d-md-none" />
              <span>{props.heading.text}</span>
            </h4>
            <p>{props.heading.subHeading}</p>
          </div>
        </div>
      )
    );
  };
  
  export default SubHeading;
  