import React, {
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Accordion } from 'react-bootstrap';
import { useDataIngestion } from '../../../Hooks/useDataIngestion';
import VisitorParamsContext from '../../../Contexts/VisitorParams';
import GetLenders from '../../../Utility/GetLenders';
import { getQueryString } from '../../../Utility/QueryString';
import GTMDataLayer from '../../../Utility/GTMDataLayer';
import LenderSelectModal from '../../Includes/Layouts/TCGL_V_A1/LenderSelectModal';
import Button from '../../UI/Button';
import "../../../assets/css/TCGL_V_A1/lenders.scss"

let functionTriggered = false;
const CombinedLenderAccordionsV5 = ({ autoSelectAllLenders, previousSlideChange, slideChange,setSelectedLender,selectedLender }) => {
  const divRef = useRef(null);
  const [activeKey, setActiveKey] = useState(null);
  const [checked, setChecked] = useState('0');

  const { visitorParameters } = useContext(VisitorParamsContext);
  const { saveDataIngestion, isLoading } = useDataIngestion();
  const { getLenderList } = GetLenders();
  const queryString = getQueryString();
  const urlParams = new URLSearchParams(window.location.search);
  const splitName = urlParams.get('splitName')
    ? urlParams.get('splitName')
    : localStorage.getItem('split_name');
  const uuid = urlParams.get('uuid') ? urlParams.get('uuid') : localStorage.getItem('uuid');
  //const splitName = new URLSearchParams(queryString).get("splitName");
  //const uuid = new URLSearchParams(queryString).get("uuid");
  const message_type = 'lender_store';
  visitorParameters.visitor_parameters.uuid = uuid;

  const [hasSkipped, setHasSkipped] = useState(true);
  const [filteredLenders, setFilteredLenders] = useState([]);
  const [blink, setBlink] = useState(false);
  const [showModal, setShowModal] = useState({ errorStatus: false });
  const [validationError, setValidationError] = useState({
    errorStatus: false,
    message: '',
  });
  const [openIndex, setOpenIndex] = useState(null);
  const [creditCheckLenders, setCreditCheckLenders] = useState({
    creditCheckSelectedLenders: [],
    creditCheckDeSelectedLenders: [],
  });
  const [useTempLenderSection, setUseTempLenderSection] = useState(true);

  const handleToggle = (event, index) => {
    // event.stopPropagation();
    setOpenIndex(openIndex === index ? null : index);
  };

  // Load selected banks from localStorage on page load
  const setAlreadySelectedLendersFunction = () => {
    // const storedBanks = localStorage.getItem("selectedBanks");
    // const LenderDB_Count = localStorage.getItem("LenderDB_Count");
    const creditCheckLendersLocal = localStorage.getItem('creditCheckLenders');
    const deSelectedLendersLocal = JSON.parse(localStorage.getItem('deSelectedLenders')) || [];
    setCreditCheckLenders((prevState) => ({
      ...prevState,
      creditCheckSelectedLenders: JSON.parse(creditCheckLendersLocal),
      creditCheckDeSelectedLenders: deSelectedLendersLocal,
    }));
    // if (creditCheckLendersLocal.length > 1 || storedBanks.length > 1) {
    //   setBlink(true);
    // }
    // if (storedBanks) {
    //   setSelectedLender(JSON.parse(storedBanks));
    // }
  };

  useEffect(() => {
    if (autoSelectAllLenders === true && filteredLenders.length > 0) {
      // for setting all lenders as selected by default
      const selectedLenders = filteredLenders.map((item) => {
        const { sublender, ...selectedLender } = item;
        return selectedLender;
      });
      setSelectedLender(selectedLenders);
      // end
    } else if (!autoSelectAllLenders) {
      setSelectedLender([]);
    }
  }, [autoSelectAllLenders, filteredLenders]);

  useEffect(() => {
    (async () => {
      const response = await getLenderList(
        visitorParameters.visitor_parameters.uuid,
        setHasSkipped
      );
      setFilteredLenders(response);

      // for setting all lenders as selected by default
      // const selectedLenders = response.map((item) => {
      //   const { sublender, ...selectedLender } = item;
      //   return selectedLender;
      // });
      // setSelectedLender(selectedLenders);
      // end
      setAlreadySelectedLendersFunction();
    })();
  }, []);

  useEffect(() => {
    if (
      // creditCheckLenders.creditCheckSelectedLenders.length !=
      // creditCheckLenders.creditCheckDeSelectedLenders.length ||
      selectedLender.length > 0
    ) {
      setBlink(true);
    } else if (
      // creditCheckLenders.creditCheckSelectedLenders.length ==
      // creditCheckLenders.creditCheckDeSelectedLenders.length &&
      selectedLender.length < 1
    ) {
      setBlink(false);
    }
  }, [creditCheckLenders, selectedLender]);

  const clickEvent = (e, id, bankcode, label, value, tempLenderSectionClick = false) => {
    if (tempLenderSectionClick === true) {
      setSelectedLender([{ id, bankcode, label, value }]);
      setUseTempLenderSection(false);
    } else {
      // Check if the selected lender and sublender already exist in the state
      const exists = selectedLender.some((item) => item.id === id);
      // If exists, filter it out, otherwise add the new lenderId and sublenderId
      const updatedData = exists
        ? selectedLender.filter((item) => !(item.id === id))
        : [...selectedLender, { id, bankcode, label, value }];

      setSelectedLender(updatedData);
      setValidationError({
        errorStatus: updatedData.length < 1,
        // && creditCheckLenders?.creditCheckDeSelectedLenders.length ==
        // creditCheckLenders?.creditCheckSelectedLenders.length
        message: 'Please Select Your Finance provider',
      });
    }
  };
  const LenderSection1 = [];
  filteredLenders.map((value, index) => {
    const element = (
      <div
        key={`${index}`}
        style={{ position: 'relative' }}>
        <input
          type='checkbox'
          className={`test`}
          value={value.value}
          id={`lender_${index}`}
          name={`lender_${index}`}
          onChange={(e) => {
            clickEvent(e, value.id, value.bank_code, value.label, value.value);
          }}
          checked={selectedLender.some((item) => item.id === value.id) ? true : false}
        />

        <label
          htmlFor={`lender_${index}`}
          className='selected-false'>
          <span className='option_title title_1'>{value.label}</span>
        </label>
        {value.sublender && value.sublender.length > 0 && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              position: 'absolute',
              right: '1%',
              cursor: 'pointer',
            }}
            // className={`px-2` ${(openIndex == index) ? `top-div` : `top-sub`} }
            className={`px-2 ${openIndex === index ? 'top-sub' : 'top-div'}`}
            onClick={(e) =>
              value.sublender && value.sublender.length > 0 ? handleToggle(e, index) : null
            } // Toggle the accordion on click if sublenders exist
          >
            <span className='arrow-icon'>Group ▼</span>
          </div>
        )}
        {openIndex == index && (
          <div className='lender-div'>
            <ul className='lender-list'>
              {value.sublender &&
                value.sublender.length > 0 &&
                value.sublender.map((subLender, subIndex) => (
                  <li key={subIndex}>
                    {subLender.label}
                    <hr />
                  </li>
                ))}
            </ul>
          </div>
        )}
      </div>
    );

    // if (index <= 10) {
    LenderSection1.push(element);
    // } else {
    //   LenderSection2.push(element);
    // }
  });


  useEffect(() => {
    if (selectedLender.length < 1) {
      if (validationError.errorStatus && !showModal.errorStatus && functionTriggered) {
        setTimeout(() => {
          const element = divRef.current;
          const topPosition = element.getBoundingClientRect().top + window.scrollY;
          window.scrollTo({
            top: topPosition,
            behavior: 'smooth',
          });
          functionTriggered = false;
        }, 500);
      }
    } else {
      setValidationError({
        errorStatus: false,
        message: '',
      });
    }
  }, [selectedLender, validationError.errorStatus, showModal.errorStatus]);

  const handleLenders = async (e) => {
    if (
      selectedLender.length < 1
      // && creditCheckLenders.creditCheckSelectedLenders.length ==
      // creditCheckLenders.creditCheckDeSelectedLenders.length
    ) {
      setShowModal({ errorStatus: true });
      setValidationError({
        errorStatus: true,
        message: 'Please Select Your Finance provider',
      });
      functionTriggered = true;
      return false;
    } 
    else {
      // Retrieve existing selected banks from localStorage
      let existingSelectedBanks = JSON.parse(localStorage.getItem('selectedBanks')) || [];

      // Remove banks from existingSelectedBanks if they are not in selectedLender
      let updatedSelectedBanks = existingSelectedBanks.filter((existingBank) =>
        selectedLender.some((newBank) => newBank.id === existingBank.id)
      );

      // Filter out values from selectedLender that are not already present in updatedSelectedBanks (to add new ones)
      let newBanksToAdd = selectedLender.filter(
        (newBank) => !updatedSelectedBanks.some((existingBank) => existingBank.id === newBank.id)
      );

      // Append only the new banks that are not already in updatedSelectedBanks
      updatedSelectedBanks = [...updatedSelectedBanks, ...newBanksToAdd];

      // Store the updated list back in localStorage
      localStorage.setItem('selectedBanks', JSON.stringify(updatedSelectedBanks));
      
      setValidationError({ errorStatus: false, message: '' });

      GTMDataLayer({
        clickEvent: 'slideclick',
        question: 'select lenders',
        answer: '',
      });
      return true;
    }
  }

  const hidepopup = () => {
    setShowModal({ errorStatus: false });
  };

  // fix for setting accordion color and check based on which section lender is selected
  useEffect(() => {
    if (useTempLenderSection && selectedLender.length > 0) {
      setChecked('0');
    } else if (!useTempLenderSection && selectedLender.length > 0) {
      setChecked('1');
    }
  }, [useTempLenderSection, selectedLender, activeKey]);

  return (
    <div ref={divRef} className='TCGL_V_A1_Lenders'>
      <fieldset className='mb-3'>
        <legend className='px-2 lg-mb-3 text-start'>Your Car Finance Lenders:</legend>
        <div className='lenders'>
          <div className='selectoptions'>{LenderSection1}</div>
        </div>

        {validationError.errorStatus && (
          <span
            className='error_msg '
            id='txtEmail_err'>
            <label
              id='error'
              className='error_msg'>
              {validationError.message}
            </label>
          </span>
        )}
      </fieldset>
      <LenderSelectModal
        showstatus={showModal.errorStatus}
        hidepopup={hidepopup}
      />

      <div className='row mob_sticky'>
        <div className="col-12 row">
          <div className="col-4">
            <Button
              type="button"
              name="back0"
              className="btn-back  mt-4 prev-slide1"
              value="Back"
              onClick={previousSlideChange}
              buttonText="< Back"
            />
          </div>
          <div className="col-8 pr-0">
            <Button
              type="button"
              name="slide0"
              className="btn-banner btn-fullwidth mt-4 next-slide3"
              value="Start Claims"
              onClick={async (e) => { 
                const isLenderValid = await handleLenders();
                if (isLenderValid && selectedLender.length > 0) {
                  slideChange(e);
                }
              }}
              buttonText="Start Claims"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CombinedLenderAccordionsV5;