import React from 'react'
import Button from '../../../UI/Button'
import ReviewSliderCarousel from './ReviewSliderCarousel'
import review_img from "../../../../assets/img/TCGL_V_A1/review.4e871c00.webp";
import cars_video from "../../../../assets/img/TCGL_V_A1/Cars.mp4";
import { Anchor } from 'react-bootstrap';
import VideoBasedOnDomain from '../Common/VideoBasedOnDomain';


const LPBanner = (props) => {
	return (
		<>
			<section className="banner bner">
				<div className="news-ticker pos_stick" id="dynamic">
					<div className="ticker-content">
						<Anchor
							className="text-decoration-none"
							href=""
							target="_blank"
						>
							<div className="logos active">
								<img src={props.bbc} className="bbcLogo" alt="bbc logo" />
								<div className="contenty">
									Car finance scandal could cost lenders £30bn warns leading ratings
									agency – Car Dealer Magazine
								</div>
							</div>
						</Anchor>
					</div>
				</div>
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-7 col-md-12 col-sm-12 align-self-center order-lg-1 order-2">
							<div className="overflow-hidden">
								<h1 className="drop-in tex-lg-start text-md-start text-sm-center text-center">
									Reclaim Up To £1,094.12* {" "}
									<br className="d-block d-md-none" />
									per vehicle!
								</h1>
								<h2>
								Free Agreement Check<sup>#</sup>
								</h2>
								<h2>Locate Agreements Since 2007</h2>
								<h2>Quick (Start in 60 seconds)</h2>
								<Button
									type="button"
									className="btn-banner zoom-in-zoom-out goto-page-personal mb-4"
									onClick={props.handleclick}
									buttonText={
										<>
											Start Agreement Check
											<span>
												<img src={props.btn_icon} alt="" height={30} />
											</span>
										</>
									}
								/>
								<img src={props.cloudflare} alt="" height={40} />
							</div>
							
							<ReviewSliderCarousel
								review_img ={review_img}
							/>
						</div>
						<div className="col-lg-5 col-md-12 col-sm-12 align-self-center order-lg-2 order-1 mt-lg-0 mt-4">
							<div className="text-center video_play mb-lg-0 mb-4 mt-4">
								{/* <video className="video-bg" autoPlay muted loop playsInline>
									<source src={cars_video} type="video/mp4" />
								</video> */}
								<VideoBasedOnDomain className="video-bg"/>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export default LPBanner