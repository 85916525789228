import { useState, useEffect } from 'react';
import { Document, Page } from 'react-pdf';
import pdfimg from "../../assets/img/TCGL_V_A1/pdf.png"
import loader from '../../assets/img/loader02.gif';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PdfSimpleModal = ({ pdfDoc, showPdfModal, setShowPdfModal }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageWidth, setPageWidth] = useState(window.innerWidth * 0.9);

  const closePdfModal = () => setShowPdfModal('hide');

  const onLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const updatePageWidth = () => {
    const modalMaxWidth = 800; // Set a max width for the modal
    setPageWidth(Math.min(window.innerWidth * 0.9, modalMaxWidth)); // 90% of the viewport or modal max width
  };

  useEffect(() => {
    updatePageWidth(); // Update on component mount
    window.addEventListener('resize', updatePageWidth); // Update on window resize
    return () => window.removeEventListener('resize', updatePageWidth);
  }, []);


  return (
    <>
      <div
        className={`modal fade blackover ${showPdfModal}`}
        id="loa"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body">
              <div className="modal-header  border border-0 pb-0">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closePdfModal} style={{ backgroundColor: 'red', position: 'fixed', right: '60px', left: 'auto', zIndex: '10' }}></button>
              </div>
              <div className="container-sm my-4 Cpa_page_style d-flex align-items-center justify-content-center">
                {showPdfModal === 'hide' && !numPages ? (
                  <>
                  </>
                ) : (
                  <Document
                    file={pdfDoc}
                    loading={<>
                      <p>{`Loading ...`}</p>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={loader} alt="loader" width={50} height={50} />
                      </div>
                    </>}
                    onLoadSuccess={onLoadSuccess}
                    onLoadError={(error) => console.error("Error loading PDF:", error)}
                  >
                    {/* Loop through each page */}
                    {Array.from(new Array(numPages), (_, index) => (
                      <Page
                        key={index}
                        pageNumber={index + 1}
                        width={pageWidth}
                      />
                    ))}
                  </Document>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PdfSimpleModal;