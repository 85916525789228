import React, { useEffect, useState } from 'react'
import "../../../assets/css/TCGL_V_A1/custom.scss";
import "../../../assets/css/TCGL_V_A1/slider.scss";
import btn_icon from "../../../assets/img/TCGL_V_A1/btn-icon.png";
import cloudflare from "../../../assets/img/TCGL_V_A1/cloudflare.png";
import bbc from "../../../assets/img/TCGL_V_A1/bbc.png";
import Footer from '../../Includes/Layouts/TCGL_V_A1/Footer';
import AdtopiaLoadLP from '../../../Utility/AdtopiaLoadLP';
import FbViewPixel from '../../../Utility/FbViewPixel';
import FAQSection from '../../Includes/Layouts/TCGL_V_A1/FAQSection';
import { getQueryString } from '../../../Utility/QueryString';
import { useHistory } from "react-router-dom";
import LPBanner from '../../Includes/Layouts/TCGL_V_A1/LPBanner';
import GTMDataLayer from '../../../Utility/GTMDataLayer';

const TCGL_V_A5 = () => {
	const [showFaq, setShowFaq] = useState('show');
	const [isVisible, setIsVisible] = useState(false);
	const history = useHistory();

	useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

	const handleclick = async () => {
		GTMDataLayer({
			clickEvent: 'slideclick',
			question: 'start agreement check',
			answer: '',
		  });
    const splitName = "TCGL_V_A5";
    history.push(
      `/TCGL_V_A5/personal-details?${getQueryString()}&splitName=${splitName}`
    );
  };
	return (
		<>
		<AdtopiaLoadLP pageType="LP" splitName="TCGL_V_A5" />
      	<FbViewPixel />
			<div className='TCGL_V_A1'>
				<LPBanner 
				handleclick={handleclick}
				btn_icon={btn_icon}
				cloudflare={cloudflare}
				bbc={bbc}
				/>
				<FAQSection
					showFaq={showFaq}
					onClick={handleclick}
					isVisible={isVisible}
				/>
				<div className="btm_sec">
					<Footer showMoreContent={true} />
				</div>

			</div>
		</>

	)
}

export default TCGL_V_A5